import { createContext, ReactNode, useContext } from 'react'

import { NonNullable } from '~/src/types'
import { PageDataQuery, PageId, usePageDataQuery } from '~/src/generated/graphql'

type Page = NonNullable<NonNullable<PageDataQuery['pages']>[0]>;

type PageContextType = {
  data: Page;
};

export const PageMetaContext = createContext<PageContextType>({
  data: {} as Page,
})

interface Props {
  children: ReactNode;
  pageId: PageId;
}

export function PageMetaContextProvider({ children, pageId }: Props) {
  const { data, loading, error } = usePageDataQuery({
    variables: {
      page: pageId,
    },
  })

  const page = data?.pages?.[0]

  if (loading || error || !page) return null

  return <PageMetaContext.Provider value={{ data: page }}>{children}</PageMetaContext.Provider>
}

export const usePageMetaContext = () => useContext(PageMetaContext)
