export const GA_TRACKING_ID = "G-82HCP0F8YQ";

declare global {
  interface Window { 
    gtag: any; 
  }
}

interface GAEventParams {
  action: string
  category: string
  label: string
  value: string
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GAEventParams) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
