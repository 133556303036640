import { createContext, useContext, ReactNode } from 'react'
import { ApolloError } from '@apollo/client'
import { useRouter } from 'next/router'

import { FestivalQuery, useFestivalQuery } from '~/src/generated/graphql'

type FestivalContextType = {
	data: FestivalQuery['festival']
	loading: boolean
	error?: ApolloError | null
}

export const FestivalContext = createContext<FestivalContextType>({
	data: null,
	loading: false,
	error: null
})

interface Props {
	children: ReactNode 
}

export function FestivalContextProvider({ children }: Props) {
	const router = useRouter()

	const { data, loading, error } = useFestivalQuery({
		variables: {
			id: router.query.festivalId as string
		},
		skip: !Boolean(router.query.festivalId)
	})

	const festival = data?.festival

	return (
		<FestivalContext.Provider value={{ data: festival, loading, error }}>
			{children}
		</FestivalContext.Provider>
	)
}


export const useFestivalContext = () => useContext(FestivalContext)