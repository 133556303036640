import { ApolloError } from '@apollo/client'
import { useRouter } from 'next/router'
import { createContext, useContext, ReactNode } from 'react'
import { FestivalItem } from '~/src/types'
import { useFestivalsQuery, useFestivalYearsQuery } from '~/src/generated/graphql'
import { nonNullable } from '~/src/utils'

type FestivalContextType = {
	items: FestivalItem[] 
	years: number[]
	loading: boolean
	error?: ApolloError | null
}

export const FestivalsContext = createContext<FestivalContextType>({
	items: [],
	years: [],
	loading: false,
	error: null
})

interface Props {
	children: ReactNode 
}

const useFestivalYears = () => {
	const { data } = useFestivalYearsQuery()
	return data?.festivalsConnection?.groupBy?.year?.map(v => v?.key).filter(nonNullable).sort((a, b) => b - a) ?? []
}

export function FestivalsContextProvider({ children }: Props) {
	const years = useFestivalYears()
	const router = useRouter()
	const queryYear = router.query.year

	const selectedYear = queryYear ? Number(queryYear): years[0]

	const { data, loading, error } = useFestivalsQuery({ 
		variables: {
			year: selectedYear,
		},
		skip: !selectedYear
	})

	const items = data?.festivals?.filter(nonNullable) ?? []
	
	return (
		<FestivalsContext.Provider value={{ items: items, years, loading, error }}>
			{children}
		</FestivalsContext.Provider>
	)
}

export const useFestivalsContext = () => useContext(FestivalsContext)