import getConfig from 'next/config'
import { Phase } from './phase'

type ConfigType = {
  apiUrl: string;
  phase: Phase
};

let config: ConfigType
const phase = process.env.PHASE || 'local'

if (typeof window === 'object') {
  const { publicRuntimeConfig } = getConfig()
  config = publicRuntimeConfig
} else {
  const serverConfig = require(`./environment/${phase}`)
  config = { ...serverConfig, phase } 
}

export default config
