import { NonNullable } from '~/src/types'

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return !!value
}

export function isNotEmpty<T>(arr?: T[] | null): arr is NonNullable<T[]> {
  if (!arr) return false
  return arr.length > 0
}

export function isEmpty<T>(arr?: T[] | null): arr is [] {
  return !isNotEmpty(arr)
}
