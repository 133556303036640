import { Reference, FieldPolicy } from '@apollo/client'

type KeyArgs = FieldPolicy<any>["keyArgs"];
// A basic field policy that uses options.args.{offset,limit} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.

interface StrapiConnection<T> {
	values: T[] 
}

export function offsetLimitPagination<T = Reference>(
  keyArgs: KeyArgs = false,
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {

      const merged = existing ? existing.slice(0) : [];

      if (args) {
        // Assume an offset of 0 if args.offset omitted.
        const { start = 0 } = args;

        for (let i = 0; i < incoming.length; i++) {
          merged[start + i] = incoming[i];
        }
      } else {
        // It's unusual (probably a mistake) for a paginated field not
        // to receive any arguments, so you might prefer to throw an
        // exception here, instead of recovering by appending incoming
        // onto the existing array.
        merged.push.apply(merged, incoming as any);
      }

      return merged
    },
  };
}

export function strapiConnectionPagination<T = Reference>(
  keyArgs: KeyArgs = false,
): FieldPolicy<StrapiConnection<T>> {
  return {
    keyArgs,
    merge(_existing, _incoming, { args }) {
			const existing = _existing?.values
			const incoming = _incoming?.values

			if(!(_incoming?.values?.length > 0)) return _incoming

      const merged = existing ? existing.slice(0) : [];

      if (args) {
        // Assume an offset of 0 if args.offset omitted.
        const { start = 0 } = args;

        for (let i = 0; i < incoming.length; i++) {
          merged[start + i] = incoming[i];
        }
      } else {
        // It's unusual (probably a mistake) for a paginated field not
        // to receive any arguments, so you might prefer to throw an
        // exception here, instead of recovering by appending incoming
        // onto the existing array.
        merged.push.apply(merged, incoming as any);
      }
      return {
				..._incoming,
				values: merged,
			};
    },
  };
}
