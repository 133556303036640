import { parse } from 'node-html-parser'

const allowAttributeMap = {
  img: ['src', 'width', 'height'],
}

export function cleanupHtml(html: string) {
  const root = parse(html)

  Object.entries(allowAttributeMap).forEach(([tagName, allowedAttributes]) => {
    const tags = root.querySelectorAll(tagName)

    tags.forEach((el) => {
      Object.keys(el.attributes).forEach((k) => {
        if (!allowedAttributes.includes(k)) el.removeAttribute(k)
      })
    })
  })

  return root.toString()
}
