import { createContext, ReactNode, useContext, useMemo } from 'react'
import { ApolloError } from '@apollo/client'

import { CategoriesQuery, useCategoriesQuery } from '~/src/generated/graphql'
import { NonNullable } from '~/src/types'
import { nonNullable } from '~/src/utils'

type CategoriesMap = {
  [key: string]: Category[];
};

type Category = NonNullable<CategoriesQuery['categories']>[0];
type CategoriesContextType = {
  categoriesMap: CategoriesMap;
  categories: Category[];
  loading: boolean;
  error: ApolloError | undefined;
};

const CategoriesContext = createContext<CategoriesContextType>({
  categoriesMap: {},
  categories: [],
  loading: false,
  error: undefined,
})

interface Props {
  children: ReactNode;
}

export function CategoriesContextProvider({ children }: Props) {
  const { data, loading, error } = useCategoriesQuery()
  const categories = useMemo(() => data?.categories?.filter(nonNullable) ?? [], [data])

  // 일단 2depth 가정하고 처리
  const categoriesMap = useMemo(() => {
    if (categories.length === 0) return {}
    const lv1 = categories.filter((category) => !Boolean(category.parent))

    return lv1.reduce((acc: CategoriesMap, v: Category) => {
      const id = v?.categoryId
      if (!id) return acc
      acc[id] = categories.filter((category) => category.parent === id).sort((a, b) => (Number(a.order) || Number.MAX_VALUE) - (Number(b.order) || Number.MAX_VALUE)) // eslint-disable-line no-param-reassign
      return acc
    }, {})
  }, [categories])


  if (loading) return null

  return (
    <CategoriesContext.Provider value={{ categories, categoriesMap, loading, error }}>
      {children}
    </CategoriesContext.Provider>
  )
}

export const useCategoriesContext = () => useContext(CategoriesContext)
