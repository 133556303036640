import withApollo from 'next-with-apollo'
import { InMemoryCache, ApolloProvider, ApolloClient } from '@apollo/client'
import config from '~/src/config'

import { offsetLimitPagination, strapiConnectionPagination } from './pagination'

export default withApollo(
  ({ initialState }) => new ApolloClient({
    uri: `${config.apiUrl}/graphql`,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            posts: offsetLimitPagination(['where', 'start', 'limit']),
            postsConnection: strapiConnectionPagination(['where'])
          }
        },
        Post: {
          fields: {
            publishDate(publishDate, cache) {
              const publishedAt = cache.readField('published_at')
              return publishDate || publishedAt
            }
          }  
        },
      }
    }).restore(initialState || {}),
  }),
  {
    render: ({ Page, props }) => (
      <ApolloProvider client={props.apollo}>
        <Page {...props} />
      </ApolloProvider>
    ),
  },
)
