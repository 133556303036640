import type { AppProps as NextAppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { getDataFromTree } from '@apollo/client/react/ssr'

import withApollo from '~/src/libs/withApollo'
import { CategoriesContextProvider } from '~/src/contexts'
import { getTitle } from '~/src/utils'
import { usePageView } from '~/src/hooks/usePageView'

import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'react-aspect-ratio/aspect-ratio.css'

import '~/styles/font.scss'
import '~/styles/globals.scss'
import '~/styles/swiper-custom.scss'

interface AppProps extends NextAppProps {
  apollo: ApolloClient<InMemoryCache>;
}

function MintPaperApp({ Component, pageProps, apollo }: AppProps) {
  usePageView()

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, minimum-scale=1' />
        <link
          rel='preconnect'
          href='https://fonts.googleapis.com' />
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com' />
        <link
          href='https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'
          rel='stylesheet'
        />
        <link
          rel="icon"
          href="favicon16.png"
          sizes="16x16" /> 
        <link
          rel="icon"
          href="favicon32.png"
          sizes="32x32" />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="crossorigin"
          href="/fonts/scdream4.woff2" />
        <link
          rel="preload"
          as="font"
          type="font/woff"
          crossOrigin="crossorigin"
          href="/fonts/scdream4.woff" />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="crossorigin"
          href="/fonts/scdream7.woff2" />
        <link
          rel="preload"
          as="font"
          type="font/woff"
          crossOrigin="crossorigin"
          href="/fonts/scdream7.woff" />
        <title>{getTitle()}</title>
      </Head>
      <ApolloProvider client={apollo}>
        <CategoriesContextProvider>
          <Component {...pageProps} />
          <div id='modal-root'></div>
        </CategoriesContextProvider>
      </ApolloProvider>
    </>
  )
}

MintPaperApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext)
  return { ...appProps }
}

export default withApollo(MintPaperApp, { getDataFromTree })
