import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { pageview } from '~/src/utils/ga'
import { Phase } from '~/src/config/phase'
import config from '~/src/config'

export const usePageView = () => {
  const router = useRouter()
  useEffect(() => {
    if (config.phase !== Phase.Production) return
    const handleRouteChange = (url: string) => {
      pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])
}
