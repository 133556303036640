export enum Phase {
  Local = 'local',
  Development = 'development',
  Production = 'production',
}

export function getPhase() {
  const phase = process.env.PHASE || 'local'
  switch (phase) {
    case 'local':
      return Phase.Local
    case 'development':
      return Phase.Development
    case 'production':
      return Phase.Production
  }
  return Phase.Local
}
