
type BuildPostQueryParam = {
	festivalId?: string
	categoryId?: string
	categories?: string[]
	tagSlug?: string
}

type BuildPostPrevNextQueryParam = {
	publishDate: string
	postId: string
	festivalId?: string 
	categoryId?: string
  order?: number | null
}

export const buildPostQuery = ({ festivalId, categoryId, categories, tagSlug }: BuildPostQueryParam) => {
  const query = {
    published_at_null: false
  } as any

  if (festivalId) {
    query.festivals = {
      id: festivalId
    } 
  }

  if (categoryId) {
    query.category = {
      categoryId_eq: categoryId
    }
  }

  if (categories) {
    query.category = {
      categoryId_in: categories
    }
  }

  if (tagSlug) {
    query.tags = {
      slug_eq: tagSlug
    }
  }

  return query
}

export const buildPostSearchQuery = (query: string) => ({
  _or: [
    {
      title_contains: query
    },
    {
      content_contains: query
    }
  ],
  published_at_null: false
})

export const buildPostPrevNextQuery = ({ festivalId, postId, publishDate, categoryId, order }: BuildPostPrevNextQueryParam) => {
  const common = {
    ...(festivalId && {
      festivals: {
        id: festivalId
      }
    }),
    category: {
      categoryId_eq: categoryId
    },
    id_ne: postId,
    published_at_null: false
  }
	
	
  return {
    prevWhere: {
      ...common,
      _or: [
        {
          order_lt: order
        },
        {
          order_eq: order,
          publishDate_gt: publishDate
        },
        {
          order_eq: order,
          publishDate_eq: publishDate,
          id_lte: postId
        }
      ]
    },
    nextWhere: {
      ...common,
      _or: [
        {
          order_gt: order
        },
        {
          order_eq: order,
          publishDate_lt: publishDate
        },
        {
          order_eq: order,
          publishDate_eq: publishDate,
          id_gte: postId
        }
      ]
    }
  }
}